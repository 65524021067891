const routes = {
  home: "/",
  prologue: "/prologue/prologue",
  rooms: "/rooms/:rooms",
  specials: "/specials/:special",
  recommend: "/travels/recommend",
  direction: "/direction/direction",
  guide: "/reservation/guide",
  notices: "/reservation/notices",
  notice: "/reservation/notice/:noticeId",
  notice_input: "/reservation/notice/input",
  question: "/reservation/question/:faqId",
  question_input: "/reservation/question/input",
  questions: "/reservation/questions",
};

export default routes;
