import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBlog,
  faPhone,
  faCalendarAlt,
  faUmbrellaBeach,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import useWindowSize from "../../hooks/useWindowSize";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import routes from "../../routes";
import { useRecoilState } from "recoil";
import { titleState } from "../state";

const Wrapper = styled.div``;
const NavWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 6vw repeat(7, 1fr) 6vw;
  justify-items: center;
  align-items: center;
  height: 10vh;
  border: 1px solid rgb(222, 222, 222);
  background: white;
  font-size: 0.8rem;
  text-transform: uppercase;
  /* position: fixed;
  top: 0;
  z-index: 1; */
`;
const NavLi = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  p {
    transition: transform 0.2s linear;
    cursor: pointer;
    padding: 5px;
    a {
      color: black;
    }
    &:hover {
      transform: translate(0, -10px);
      & + ul {
        visibility: visible;
        opacity: 1 !important;
      }
    }
  }
`;
const NavSub = styled.ul`
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0 !important;
  font-size: 0.7rem;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 600;
  width: 110px;
  padding: 17px 5px;
  border-radius: 3px;
  text-align: center;
  position: absolute;
  bottom: 5vh;
  background: white;
  opacity: 1;
  transition: all 0.5s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  line-height: 1.8;
  z-index: 10;
  a {
    color: black;
    opacity: 0.7;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  &:hover {
    visibility: visible;
    opacity: 1 !important;
    & + p {
      transform: translate(0, -10px) !important;
    }
  }
`;
const Social = styled.div`
  font-size: 1rem;
  justify-self: stretch;
  display: flex;
  justify-content: space-around;
`;
const Logo = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  i {
    font-size: 1.3rem;
    color: grey;
  }
  span {
    color: black;
    font-size: 0.6rem;
    margin-top: 2px;
  }
`;
const Book = styled.div`
  font-size: 1rem;
  height: 100%;
  justify-self: stretch;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgb(149, 153, 164);
  a {
    cursor: pointer;
  }
`;
const NavWrapperMB = styled.div`
  width: 100%;
  height: 10vh;
  background: white;
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const MenuWrapper = styled.div`
  width: 90px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 1.3rem;
`;
const MenusWrapper = styled.div`
  width: 100%;
  height: 90vh;
`;
const Menus = styled.div`
  position: fixed;
  top: 10vh;
  background: white;
  opacity: 0.8;
  width: 100%;
  height: 90vh;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 30vh 30vh;
`;
const Menu = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin-top: 5vh;
    text-transform: uppercase;
    font-size: 1rem;
    color: black;
    opacity: 0.8;
    transition: all 0.2s;
    cursor: pointer;
    &:hover,
    &:focus {
      transform: translate(0, -10px);
      opacity: 1;
      font-weight: 900;
      & + div {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  div {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
    margin-top: 1.5vh;
    &:hover {
      visibility: visible;
      opacity: 1;
    }
  }
  p {
    text-transform: uppercase;
    padding: 0.7vh 1vw;
    font-weight: 900;
    cursor: pointer;
    font-family: "Nanum Gothic";
    font-size: 0.85rem;
    a {
      color: black;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
`;

const Nav = () => {
  const [menu, setMenu] = useState(false);
  const [title, setTitle] = useRecoilState(titleState);
  const size = useWindowSize();
  useEffect(() => {
    setMenu(false);
  }, [title]);
  return (
    <Wrapper>
      {size.width > 1024 ? (
        <NavWrapper>
          <Social>
            <a
              href="https://search.naver.com/search.naver?where=post&sm=tab_pge&query=%ED%95%99%EC%95%94%ED%8F%AC%20%ED%8F%AC%EC%84%B8%EC%9D%B4%EB%8F%88"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faBlog} color="#9EA3A9" />
            </a>
            <FontAwesomeIcon icon={faInstagram} color="#9EA3A9" />
          </Social>
          <NavLi>
            <p>
              <Link onClick={() => setTitle("View")} to={routes.prologue}>
                Prologue
              </Link>
            </p>
            <NavSub>
              <li>
                <Link onClick={() => setTitle("View")} to={routes.prologue}>
                  Prologue
                </Link>
              </li>
            </NavSub>
          </NavLi>
          <NavLi>
            <p>
              <Link to="/rooms/deluxe" onClick={() => setTitle("Delux")}>
                Rooms
              </Link>
            </p>
            <NavSub>
              <li>
                <Link to="/rooms/deluxe" onClick={() => setTitle("Delux")}>
                  디럭스
                </Link>
              </li>
              <li>
                <Link to="/rooms/sweet" onClick={() => setTitle("Sweet")}>
                  스위트
                </Link>
              </li>
              <li>
                <Link to="/rooms/premium" onClick={() => setTitle("Premium")}>
                  프리미엄
                </Link>
              </li>
            </NavSub>
          </NavLi>
          <NavLi>
            <p>
              <Link onClick={() => setTitle("Ocean")} to="/specials/ocean">
                Special
              </Link>
            </p>
            <NavSub>
              <li>
                <Link onClick={() => setTitle("Ocean")} to="/specials/ocean">
                  해변
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => setTitle("Barbecue")}
                  to="/specials/barbecue"
                >
                  바베큐
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => setTitle("Fishing super")}
                  to="/specials/fishing super"
                >
                  낚시슈퍼
                </Link>
              </li>
              <li>
                <Link onClick={() => setTitle("Sea")} to="/specials/sea">
                  갯벗체험
                </Link>
              </li>
              <li>
                <Link onClick={() => setTitle("Squib")} to="/specials/squib">
                  폭죽
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => setTitle("Fishing")}
                  to="/specials/fishing"
                >
                  낚시
                </Link>
              </li>
            </NavSub>
          </NavLi>
          <Link onClick={() => setTitle("Poseidon")} to={routes.home}>
            <Logo>
              <i>
                <FontAwesomeIcon icon={faUmbrellaBeach} />
              </i>
              <span>POSEIDON</span>
            </Logo>
          </Link>
          <NavLi>
            <p>
              <Link onClick={() => setTitle("Travel")} to={routes.recommend}>
                Travel
              </Link>
            </p>
            <NavSub>
              <li>
                <Link onClick={() => setTitle("Travel")} to={routes.recommend}>
                  추천여행지
                </Link>
              </li>
            </NavSub>
          </NavLi>
          <NavLi>
            <p>
              <Link onClick={() => setTitle("Direction")} to={routes.direction}>
                Direction
              </Link>
            </p>
            <NavSub>
              <li>
                <Link
                  onClick={() => setTitle("Direction")}
                  to={routes.direction}
                >
                  오시는길
                </Link>
              </li>
            </NavSub>
          </NavLi>
          <NavLi>
            <p>
              <Link onClick={() => setTitle("Guide")} to={routes.guide}>
                Reservation
              </Link>
            </p>
            <NavSub>
              <li>
                <Link onClick={() => setTitle("Guide")} to={routes.guide}>
                  예약안내
                </Link>
              </li>
              <li>
                <a
                  href="https://booking.ddnayo.com/booking-calendar-status?accommodationId=11003&channelCode=0010"
                  target="_blank"
                  rel="noreferrer"
                >
                  실시간예약
                </a>
              </li>
              <li>
                <Link onClick={() => setTitle("Notice")} to={routes.notices}>
                  공지사항
                </Link>
              </li>
              <li>
                <Link onClick={() => setTitle("FAQ")} to={routes.questions}>
                  자주묻는질문
                </Link>
              </li>
            </NavSub>
          </NavLi>
          <Book>
            <a
              href="https://booking.ddnayo.com/booking-calendar-status?accommodationId=11003&channelCode=0010"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faCalendarAlt} color="white" />
            </a>
            <a href="tel:010-8937-2647">
              <FontAwesomeIcon icon={faPhone} color="white" />
            </a>
          </Book>
        </NavWrapper>
      ) : (
        <NavWrapperMB>
          <MenuWrapper>
            {!menu ? (
              <FontAwesomeIcon
                icon={faBars}
                color="#9EA3A9"
                onClick={() => {
                  setMenu(!menu);
                }}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faTimes}
                color="#9EA3A9"
                onClick={() => {
                  setMenu(!menu);
                }}
                style={{ cursor: "pointer" }}
              />
            )}
          </MenuWrapper>
          <Link onClick={() => setTitle("Poseidon")} to={routes.home}>
            <Logo>
              <i>
                <FontAwesomeIcon icon={faUmbrellaBeach} />
              </i>
              <span>POSEIDON</span>
            </Logo>
          </Link>
          <Book style={{ width: "70px", fontSize: "0.9rem" }}>
            <a
              href="https://booking.ddnayo.com/booking-calendar-status?accommodationId=11003&channelCode=0010"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faCalendarAlt} color="white" />
            </a>
            <a href="tel:010-8937-2647">
              <FontAwesomeIcon icon={faPhone} color="white" />
            </a>
          </Book>
        </NavWrapperMB>
      )}
      {menu && (
        <MenusWrapper>
          <Fade left when={menu}>
            <Menus className="menus">
              <Menu>
                <h2>Prologue</h2>
                <div>
                  <p>
                    <Link onClick={() => setTitle("View")} to={routes.prologue}>
                      Prologue
                    </Link>
                  </p>
                </div>
              </Menu>
              <Menu>
                <h2>Rooms</h2>
                <div>
                  <p>
                    <Link to="/rooms/deluxe" onClick={() => setTitle("Delux")}>
                      디럭스
                    </Link>
                  </p>
                  <p>
                    <Link to="/rooms/sweet" onClick={() => setTitle("Sweet")}>
                      스위트
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="/rooms/premium"
                      onClick={() => setTitle("Preimum")}
                    >
                      프리미엄
                    </Link>
                  </p>
                </div>
              </Menu>
              <Menu>
                <h2>Special</h2>
                <div>
                  <p>
                    <Link
                      onClick={() => setTitle("Ocean")}
                      to="/specials/ocean"
                    >
                      해변
                    </Link>
                  </p>
                  <p>
                    <Link
                      onClick={() => setTitle("Barbecue")}
                      to="/specials/barbecue"
                    >
                      바베큐
                    </Link>
                  </p>
                  <p>
                    <Link
                      onClick={() => setTitle("Fishing super")}
                      to="/specials/fishing super"
                    >
                      낚시슈퍼
                    </Link>
                  </p>
                  <p>
                    <Link onClick={() => setTitle("Sea")} to="/specials/sea">
                      갯벗체험
                    </Link>
                  </p>
                  <p>
                    <Link
                      onClick={() => setTitle("Squib")}
                      to="/specials/squib"
                    >
                      폭죽
                    </Link>
                  </p>
                  <p>
                    <Link
                      onClick={() => setTitle("Fishing")}
                      to="/specials/fishing"
                    >
                      낚시
                    </Link>
                  </p>
                </div>
              </Menu>
              <Menu>
                <h2>Travel</h2>
                <div>
                  <p>
                    <Link
                      onClick={() => setTitle("Travel")}
                      to={routes.recommend}
                    >
                      Travel
                    </Link>
                  </p>
                </div>
              </Menu>
              <Menu>
                <h2>Direction</h2>
                <div>
                  <p>
                    <Link
                      onClick={() => setTitle("Direction")}
                      to={routes.direction}
                    >
                      오시는길
                    </Link>
                  </p>
                </div>
              </Menu>
              <Menu>
                <h2>Reservation</h2>
                <div>
                  <p>
                    <Link onClick={() => setTitle("Guide")} to={routes.guide}>
                      예약안내
                    </Link>
                  </p>
                  <p>
                    <a
                      href="https://booking.ddnayo.com/booking-calendar-status?accommodationId=11003&channelCode=0010"
                      target="_blank"
                      rel="noreferrer"
                    >
                      실시간예약
                    </a>
                  </p>
                  <p>
                    <Link
                      onClick={() => setTitle("Notice")}
                      to={routes.notices}
                    >
                      공지사항
                    </Link>
                  </p>
                  <p>
                    <Link onClick={() => setTitle("FAQ")} to={routes.questions}>
                      자주묻는질문
                    </Link>
                  </p>
                </div>
              </Menu>
            </Menus>
          </Fade>
        </MenusWrapper>
      )}
    </Wrapper>
  );
};
export default Nav;
