import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Modal from "../../components/Modal";
import PageHeader from "../../components/page/PageHeader";
import Table from "../../components/Table";
import routes from "../../routes";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { isLoggedInVar, logUserOut } from "../../apollo";
import { useHistory } from "react-router-dom";

const Wrapper = styled.section`
  padding: 15vh 0vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Buttons = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: flex-end;
  margin: 9vh 1vw 1vh 1vw;
  font-size: 0.8rem;
  font-family: "Nanum Gothic";
`;
const Button = styled.button`
  border: 0;
  margin-right: 1vw;
  background: #d4d4d4;
  padding: 1.5vh 1vw;
  font-weight: 600;
  color: #4e546f;
  border-radius: 5px;
  cursor: pointer;
`;

const NOTICE_QUERY = gql`
  query seeNotices {
    seeNotices {
      id
      createdAt
      title
      author {
        username
      }
      content
      views
    }
  }
`;

const Notices = () => {
  const columns = useMemo(
    () => [
      {
        accessor: "index",
        Header: "번호",
      },
      {
        accessor: "title",
        Header: "제목",
      },
      {
        accessor: "author",
        Header: "글쓴이",
      },
      {
        accessor: "views",
        Header: "조회",
      },
      {
        accessor: "date",
        Header: "날짜",
      },
    ],
    []
  );

  const navList = [
    { nav: "예약안내", url: routes.guide },
    {
      nav: "실시간예약",
      url: "https://booking.ddnayo.com/booking-calendar-status?accommodationId=11003&channelCode=0010",
    },
    { nav: "공지사항", url: routes.notices },
    { nav: "자주묻는질문", url: routes.questions },
  ];
  const [modal, setModal] = useState(false);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const history = useHistory();
  const { data, refetch } = useQuery(NOTICE_QUERY);

  const notices = data?.seeNotices?.map((notice) => {
    return {
      index: notice.id,
      title: notice.title,
      author: notice.author.username,
      views: notice.views,
      date: notice.createdAt.substr(0, 10),
    };
  });
  useEffect(() => refetch());

  return (
    <Wrapper>
      <PageHeader title="Notice" subTitle="공지사항" navList={navList} />
      <Buttons>
        {isLoggedIn ? (
          <>
            <Button onClick={() => history.push(routes.notice_input)}>
              공지사항 입력
            </Button>
            <Button onClick={() => logUserOut()}>로그아웃</Button>
          </>
        ) : (
          <Button onClick={() => setModal(true)}>관리자 로그인</Button>
        )}
      </Buttons>
      <Table columns={columns} data={notices || []} />
      {modal && <Modal setModal={setModal} />}
    </Wrapper>
  );
};

export default Notices;
