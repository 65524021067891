import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import PageHeader from "../components/page/PageHeader";
import deluxe1 from "../assets/images/201/201-1.jpg";
import deluxe2 from "../assets/images/201/201-2.jpg";
import deluxe3 from "../assets/images/201/201-3.jpg";
import sweet1 from "../assets/images/203/203-1.jpg";
import sweet2 from "../assets/images/203/203-2.jpg";
import sweet3 from "../assets/images/203/203-3.jpg";
import premium1 from "../assets/images/101/1011.jpg";
import premium2 from "../assets/images/101/10102.jpg";
import premium3 from "../assets/images/101/10103.jpg";

const Wrapper = styled.section`
  padding: 15vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Info = styled.div`
  padding: 12vh 5vw;
  font-family: "Nanum Gothic";
  text-align: center;
  h3 {
    font-size: 1.2rem;
    font-weight: 900;
  }
  h5 {
    font-size: 0.9rem;
    font-weight: 900;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  p {
    font-size: 0.7rem;
    color: #545454;
  }
  a {
    background-color: #8f8f8f;
    border: none;
    color: white;
    padding: 5px 40px;
    font-size: 0.6rem;
    font-family: "Nanum Gothic", sans-serif;
    cursor: pointer;
  }
`;
const Realtime = styled.div`
  margin-top: 20px;
`;
const InfoTable = styled.table`
  margin: 10px auto;
  font-size: 0.75rem;
  th,
  td {
    padding: 7px;
    border: 1px solid #e4e4e4;
  }
  th {
    font-weight: 600;
  }
`;
const Content = styled.section`
  margin: 15vh 0 10vh 0;
  padding: 0 2vw;
  width: 100%;
  /* padding: 0 5vw; */
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 850px) {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 20px;
  }
`;
const Row2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #8c8c8c;
  p {
    font-weight: 500 !important;
  }
  div {
    padding: 0 5vw;
    @media only screen and (max-width: 850px) {
      text-align: center;
    }
  }
  @media only screen and (max-width: 850px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Intro = styled.div`
  color: #545354;
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
  h2 {
    font-size: 1.7rem;
    margin-bottom: 5vh;
    text-transform: uppercase;
  }
  p {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.7;
    font-family: "Naum Gothic", sans-serif;
  }
`;
const Photo1 = styled.img`
  width: 70%;
  z-index: 10;
  position: relative;
  margin-left: 10px;
  @media only screen and (max-width: 850px) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
  }
`;
const Photo2 = styled.img`
  width: 55%;
  transform: translate(0, -50px);
  @media only screen and (max-width: 850px) {
    width: 100%;
    margin-bottom: 30px;
    transform: translate(0, 0px);
  }
`;
const Photo3 = styled.img`
  width: 100%;
  height: 80vh;
`;

const Rooms = (props) => {
  const {
    match: { params },
  } = props;
  const history = useHistory();
  let subTitle = "";
  let roomNumber = 0;
  const rooms = ["deluxe", "sweet", "premium"];
  const navList = rooms.map((room) => {
    return { nav: room, url: `/rooms/${room}` };
  });
  const room = rooms.find((room) => room === params.rooms);
  if (room === rooms[0]) {
    roomNumber = 0;
    subTitle = "디럭스룸";
  }
  if (room === rooms[1]) {
    roomNumber = 1;
    subTitle = "스위트룸";
  }
  if (room === rooms[2]) {
    roomNumber = 2;
    subTitle = "프리미엄룸";
  }
  if (!room) history.push("/");
  const info = [
    {
      title: "디럭스201(원룸)",
      construction: "침대룸A(킹1)+화장실1",
      wide: "9평 (약 29.7㎡ )",
      people: [2, 4],
    },
    {
      title: "스위트203(원룸)",
      construction: "침대룸A(퀸1)+화장실1",
      wide: "10평 (약 33.0㎡ )",
      people: [3, 5],
    },
    {
      title: "프리미엄101(투룸)",
      construction: "거실+객실+화장실1",
      wide: "15평 (약 49.5㎡ )",
      people: [4, 8],
    },
  ];

  const roomsPhoto = [
    [deluxe1, deluxe2, deluxe3],
    [sweet1, sweet2, sweet3],
    [premium1, premium2, premium3],
  ];

  return (
    <Wrapper>
      <PageHeader title={room} subTitle={subTitle} navList={navList} />
      <Info>
        <Fade up duration={2000}>
          <h3>{info[roomNumber].title}</h3>
          <h5>구조넓이</h5>
          <p>{info[roomNumber].construction}</p>
          <p>{info[roomNumber].wide}</p>
          <h5>기준인원</h5>
          <p>
            기준 {info[roomNumber].people[0]}명 ~ 최대{" "}
            {info[roomNumber].people[1]}명 / 2명 초과시 추가요금 발생
          </p>
          <InfoTable>
            <thead>
              <tr>
                <th>시즌</th>
                <th>성인</th>
                <th>아동</th>
                <th>유아</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>비수기</td>
                <td>10,000원</td>
                <td>10,000원</td>
                <td>10,000원</td>
              </tr>
              <tr>
                <td>성수기</td>
                <td>10,000원</td>
                <td>10,000원</td>
                <td>10,000원</td>
              </tr>
              <tr>
                <td>준성수기</td>
                <td>10,000원</td>
                <td>10,000원</td>
                <td>10,000원</td>
              </tr>
              <tr>
                <td>지정기간</td>
                <td>10,000원</td>
                <td>10,000원</td>
                <td>10,000원</td>
              </tr>
            </tbody>
          </InfoTable>
          <h5>구비시설</h5>
          <p>
            TV,침대,에어컨,냉장고,취사도구,전기밥솥,전자레인지,핫플레이트,인터넷,욕실용품,객실샤워실
          </p>
          <h5>특이사항</h5>
          <p>식기 구성은 4인, 2인 1침구 구성, 고기,새우,생선 취사불가</p>
          <Realtime>
            <a
              href="https://booking.ddnayo.com/booking-calendar-status?accommodationId=11003&channelCode=0010"
              target="_blank"
              rel="noreferrer"
            >
              실시간예약
            </a>
          </Realtime>
        </Fade>
      </Info>
      <Content>
        <Fade up duration={2000} cascade>
          <Row>
            <Intro>
              <h2>ROOM #{room}</h2>
              <p>
                포세이돈펜션 전 객실에서 아름다운 바다와 멋진 풍경을 제공합니다.
              </p>
              <p>
                항상 청결하고 철저한 위생관리로 편안하고 깔끔한 객실을
                준비합니다.
              </p>
              <p>
                객실내 침구류, 타올은 1투숙객 1회 매일 교체되어 청결을 유지하고
                있습니다.
              </p>
              <p>마음을 담아서</p>
              <p>행복한 시간을 보내실 여러분을 초대합니다.</p>
            </Intro>
            <Photo1 src={roomsPhoto[roomNumber][0]} />
          </Row>
        </Fade>
        <Fade up duration={2000} cascade>
          <Row2>
            <Photo2 src={roomsPhoto[roomNumber][1]} />
            <Intro>
              <p>
                A day in a fantastic southern sea and a place where stars
                shine!!!
              </p>
              <p>
                Join poseidon when you want to allow yourself to relax in your
                daily routine.
              </p>
            </Intro>
          </Row2>
        </Fade>
      </Content>
      <Fade up duration={1500}>
        <Photo3 src={roomsPhoto[roomNumber][2]} />
      </Fade>
    </Wrapper>
  );
};

export default Rooms;
