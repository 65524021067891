import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { isLoggedInVar } from "../../apollo";
import PageHeader from "../../components/page/PageHeader";
import routes from "../../routes";

const SQuestion = styled.section`
  padding: 15vh 0vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Wrapper = styled.form`
  padding: 15vh 5vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Nanum Gothic";
`;
const TitleWrapper = styled.div`
  width: 100%;
  div > div,
  div > input {
    margin-left: 10px;
  }
  border: 1px solid #ddd;
  font-size: 0.8rem;
`;
const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border: 1px solid #ddd;
`;
const Column = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Content = styled.div`
  margin: 8vh 0;
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  border: 1px solid #aaa;
  padding: 1vh 5px;
  span {
    margin-bottom: 3vh;
  }
`;
const EditContent = styled.textarea`
  margin: 8vh 0;
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  border: 1px solid #aaa;
  padding: 1vh 5px;
`;
const Button = styled(Link)`
  all: unset;
  background-color: #d4d4d4;
  font-size: 0.85rem;
  font-weight: 600;
  color: black;
  padding: 1.5vh 2.5vw;
  border-radius: 3px;
  margin: 0 5px;
  cursor: pointer;
`;
const EidtButton = styled.button`
  all: unset;
  background-color: #d4d4d4;
  font-size: 0.85rem !important;
  font-weight: 600;
  color: black;
  padding: 1.5vh 2.5vw;
  border-radius: 3px;
  margin: 0 5px;
  cursor: pointer;
`;
const FormError = styled.span`
  color: tomato;
  font-size: 0.7rem;
  margin: 0.5vh 0 1vh 0;
  text-align: center;
  font-weight: 600;
`;

const FAQ_QUERY = gql`
  query seeFaq($id: Int!) {
    seeFaq(id: $id) {
      id
      question
      answer
      createdAt
      author {
        username
      }
    }
  }
`;
const FAQ_DELETE = gql`
  mutation deleteFaq($id: Int!) {
    deleteFaq(id: $id) {
      ok
      error
    }
  }
`;
const FAQ_UPDATE = gql`
  mutation updateFaq($id: Int!, $question: String, $answer: String) {
    updateFaq(id: $id, question: $question, answer: $answer) {
      ok
      error
    }
  }
`;

const Question = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const { faqId } = useParams("faqId");
  const history = useHistory();
  const navList = [
    { nav: "예약안내", url: routes.guide },
    {
      nav: "실시간예약",
      url: "https://booking.ddnayo.com/booking-calendar-status?accommodationId=11003&channelCode=0010",
    },
    { nav: "공지사항", url: routes.notices },
    { nav: "자주묻는질문", url: routes.questions },
  ];
  const { data, loading, refetch } = useQuery(FAQ_QUERY, {
    variables: { id: parseInt(faqId) },
  });

  if (!data && !loading) {
    alert("공지사항이 없습니다.");
    history.push(routes.notices);
  }

  const [action, setAction] = useState("see");

  const onSubmitUpdate = async (params) => {
    const {
      data: {
        updateFaq: { ok, error },
      },
    } = await updateFaq({
      variables: { ...params, id: parseInt(faqId) },
    });
    if (!ok) setError("result", { message: `FAQ 수정 실패 ${error}` });
    alert("수정 완료");
    setAction("see");
    return refetch();
  };

  const [updateFaq] = useMutation(FAQ_UPDATE);
  const [deleteFaq] = useMutation(FAQ_DELETE);
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const onDelete = async () => {
    const check = window.confirm("정말 삭제하시겠습니까?");
    if (!check) return;
    const {
      data: {
        deleteFaq: { ok, error },
      },
    } = await deleteFaq({ variables: { id: parseInt(faqId) } });
    if (!ok) setError("result", { message: error });
    return history.push(routes.questions);
  };

  return (
    <SQuestion>
      <PageHeader title="Notice" subTitle="공지사항" navList={navList} />
      <Wrapper onSubmit={handleSubmit(onSubmitUpdate)}>
        <TitleWrapper>
          <TitleRow>
            <Column>
              <span> 생성자 : </span>
              <div> {data?.seeFaq?.author.username}</div>
            </Column>
            <Column>
              <span> 생성날짜 :</span>
              <div> {data?.seeFaq.createdAt.substr(0, 10)}</div>
            </Column>
          </TitleRow>
          <TitleRow>
            <Column>
              <span> 질 문 : </span>
              {action === "see" && <div> {data?.seeFaq.question}</div>}
              {action === "edit" && (
                <input
                  {...register("question")}
                  defaultValue={data?.seeFaq.question}
                />
              )}
            </Column>
          </TitleRow>
        </TitleWrapper>
        {action === "see" && <Content>{data?.seeFaq.answer}</Content>}
        {action === "edit" && (
          <EditContent
            {...register("answer")}
            defaultValue={data?.seeFaq.answer}
          ></EditContent>
        )}
        <div>
          <Button to={routes.questions}>목록보기</Button>
          {isLoggedIn && (
            <>
              {action === "see" && (
                <>
                  <EidtButton onClick={() => setAction("edit")}>
                    수정하기
                  </EidtButton>
                  <EidtButton type="button" onClick={onDelete}>
                    삭제
                  </EidtButton>
                </>
              )}
              {action === "edit" && (
                <EidtButton type="submit">수정완료</EidtButton>
              )}
            </>
          )}
        </div>
        {errors.result && <FormError>{errors.result.message}</FormError>}
      </Wrapper>
    </SQuestion>
  );
};

export default Question;
