import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const GlobalStyle = createGlobalStyle`
  ${reset}
  *{
    box-sizing: border-box;
    
  }
  body{
    font-family: 'Sorts Mill Goudy', serif;
  }
  a {
    all:unset;
    text-decoration:none;
    color:black;
    cursor:pointer;
  }
`;
