import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import out from "../../assets/images/out.jpg";
import out2 from "../../assets/images/out2.jpg";
import night from "../../assets/images/out_night.jpg";
import night2 from "../../assets/images/out_night2.jpg";
import beach2 from "../../assets/images/beach2.jpg";
import beach3 from "../../assets/images/beach3.jpg";
import glow from "../../assets/images/glow.jpg";
import glow2 from "../../assets/images/glow2.jpg";
import hakampo from "../../assets/images/hakampo.jpg";
import hakampo2 from "../../assets/images/hakampo2.jpg";
import hakampo3 from "../../assets/images/hakampo3.jpg";
import hakampo4 from "../../assets/images/hakampo4.jpg";
import hakampo5 from "../../assets/images/hakampo5.jpg";
import Fade from "react-reveal/Fade";
import { titleState } from "../state";
import { useRecoilValue } from "recoil";

const Wrapper = styled.div`
  height: 90vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    margin-top: 10vh;
  }
`;
const Img = styled.img`
  height: 90vh;
  width: 100%;
  z-index: 10;
`;
const OverWrite = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  p {
    font-size: 0.6rem;
    letter-spacing: 7px;
    margin-bottom: 10px;
  }
  h1 {
    letter-spacing: 4px;
    border: 900;
    font-size: 2rem;
    margin-bottom: 10px;
  }
  span {
    font-size: 0.4rem;
  }
`;

const Slide = () => {
  const settings = {
    infinite: true,
    // speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  const title = useRecoilValue(titleState);
  return (
    <Wrapper>
      <Slider {...settings}>
        <Img src={glow2} />
        <Img src={out2} />
        <Img src={hakampo2} />
        <Img src={beach2} />
        <Img src={hakampo} />
        <Img src={glow} />
        <Img src={hakampo3} />
        <Img src={beach3} />
        <Img src={out} />
        <Img src={night} />
        <Img src={hakampo4} />
        <Img src={night2} />
        <Img src={hakampo5} />
      </Slider>
      <OverWrite>
        <Fade up>
          <p>welcome</p>
          <h1>{title}</h1>
          <span>pension</span>
        </Fade>
      </OverWrite>
    </Wrapper>
  );
};
export default Slide;
