import Nav from "./Nav/Nav";
import Slide from "./slider/Slide";
import Footer from "./Footer";
import Bottom from "./Bottom";
import { useEffect } from "react";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { isLoggedInVar, logUserOut } from "../apollo";

const ME_QUERY = gql`
  query me {
    me {
      username
    }
  }
`;

const Layout = ({ children }) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const { data } = useQuery(ME_QUERY, { skip: !isLoggedIn });

  useEffect(() => {
    if (data?.me === null) logUserOut();
  }, [data]);
  return (
    <div>
      <Slide />
      <Nav />
      {children}
      <Bottom />
      <Footer />
    </div>
  );
};
export default Layout;
