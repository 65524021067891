/* eslint-disable no-undef */
import { useEffect } from "react";
import styled from "styled-components";
import PageHeader from "../../components/page/PageHeader";
import routes from "../../routes";
import out2 from "../../assets/images/out2.jpg";

const Wrapper = styled.section`
  padding: 15vh 0vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  width: 100%;
  padding: 0vh 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Map = styled.div`
  margin-top: 15vh;
  width: 100%;
  height: 60vh;
`;
const Navigation = styled.div`
  margin-top: 15vh;
  width: 100%;
  display: flex;
`;
const Img = styled.img`
  max-width: 40vw;
`;
const Info = styled.div`
  margin-left: 7vw;
  padding: 8vh 0;
  h3 {
    font-size: 1.2rem;
    color: #545354;
    margin-bottom: 20px;
  }
  h4 {
    color: #545354;
    font-size: 1.2rem;
    font-family: "Naum Gothic";
    margin-bottom: 40px;
  }
  p {
    font-size: 0.7rem;
    font-weight: 900;
    font-family: "Naum Gothic";
    color: #545354;
    line-height: 1.2;
  }
`;
const ByBus = styled.div`
  display: flex;
  flex-direction: column;
  color: #545354;
  padding: 15vh 0 10vh 0;
  align-self: flex-start;
  h4 {
    font-size: 1.2rem;
    margin-bottom: 3vh;
  }
  h5 {
    font-size: 1.2rem;
    font-weight: 900;
    font-family: "Nanum Gothic", sans-serif;
    margin-bottom: 4vh;
  }
  a {
    background-color: #8f8f8f;
    border: none;
    color: white;
    padding: 8px 40px;
    font-size: 0.8rem;
    font-weight: 900;
    letter-spacing: -1px;
    font-family: "Nanum Gothic", sans-serif;
    cursor: pointer;
    margin-bottom: 1vh;
  }
`;

const Direction = () => {
  useEffect(() => {
    const container = document.getElementById("map");
    const options = {
      center: new kakao.maps.LatLng(36.897431151446, 126.20583142029716),
      level: 3,
    };
    const map = new kakao.maps.Map(container, options);
    const markerPosition = new kakao.maps.LatLng(
      36.897431151446,
      126.20583142029716
    );
    const marker = new kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(map);

    const mapTypeControl = new kakao.maps.MapTypeControl();
    const zoomControl = new kakao.maps.ZoomControl();

    map.addControl(mapTypeControl, kakao.maps.ControlPosition.BOTTOMRIGHT);
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
  }, []);

  return (
    <Wrapper>
      <PageHeader
        title="Direction"
        subTitle="약도보기"
        navList={[{ nav: "오시는길", url: routes.direction }]}
      />
      <Content>
        <Map id="map" />
        <Navigation>
          <Img src={out2} />
          <Info>
            <h3>NAVIGATION</h3>
            <h4>네비게이션 이용시</h4>
            <p> - 도로명 주소 : 충남 태안군 원북면 옥파로 1152</p>
          </Info>
        </Navigation>
        <ByBus>
          <h4>BY BUS </h4>
          <h5>대중교통 </h5>
          <a
            href="https://www.taean-pti.kr/index.php?mp=p2_4_6"
            target="_blank"
            rel="noreferrer"
          >
            오실 때 시내버스 시간 보기
          </a>
          <a
            href="https://www.taean-pti.kr/index.php?mp=p3_1"
            target="_blank"
            rel="noreferrer"
          >
            가실 때 시외버스 시간 보기
          </a>
        </ByBus>
      </Content>
    </Wrapper>
  );
};

export default Direction;
