import { useState } from "react";
import styled from "styled-components";
import { faUmbrellaBeach } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBlog, faCaretSquareUp } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar, logUserOut } from "../apollo";
import Modal from "./Modal";

const Wrapper = styled.footer`
  min-height: 50vh;
  width: 100%;
  background-color: #414042;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a4a4a4;
  padding: 0 20px;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  color: #c5c5c5;
  font-size: 1.1rem;
  span {
    font-size: 0.9rem;
    margin-top: 5px;
  }
  label {
    font-size: 0.2rem;
    margin-top: 2px;
  }
`;
const Line = styled.div`
  margin-top: 5px;
  width: 100%;
  border-bottom: 1px solid #737373;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  svg {
    cursor: pointer;
  }
`;
const Social = styled.div`
  font-size: 1rem;
  display: flex;
  justify-content: space-around;
  a {
    margin-right: 10px;
  }
`;
const Phone = styled.div`
  margin-top: 25px;
  text-align: center;
  font-size: 1.1rem;
  letter-spacing: -2px;
  font-family: "Nanum Gothic", sans-serif;
  a {
    cursor: pointer;
    color: #f7f7f7;
  }
`;
const Info = styled.div`
  margin-top: 20px;
  font-size: 0.5rem;
  font-family: "Nanum Gothic", sans-serif;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  span:not(:last-child) {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #a4a4a4;
    margin-bottom: 5px;
  }
`;
const AdminLogin = styled.div`
  margin-top: 20px;
  font-size: 0.5rem;
  button {
    border: 0;
    background: none;
    padding: 1.5vh 1vw;
    font-weight: 600;
    font-family: "Nanum Gothic";
    color: #a4a4a4;
    cursor: pointer;
  }
`;
const Copyright = styled.div`
  margin-top: 20px;
  font-size: 0.5rem;
  text-align: center;
  span:not(:last-child) {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #a4a4a4;
  }
`;

const Footer = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const [modal, setModal] = useState(false);
  return (
    <Wrapper>
      <Logo>
        <FontAwesomeIcon icon={faUmbrellaBeach} color="#C5C5C5" />
        <span>Poseidon</span>
        <label>Pension</label>
      </Logo>
      <Line>
        <Social>
          <a
            href="https://search.naver.com/search.naver?where=post&sm=tab_pge&query=%ED%95%99%EC%95%94%ED%8F%AC%20%ED%8F%AC%EC%84%B8%EC%9D%B4%EB%8F%88"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faBlog} color="#9EA3A9" />
          </a>
          <FontAwesomeIcon icon={faInstagram} color="#9EA3A9" />
        </Social>
        <FontAwesomeIcon
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          icon={faCaretSquareUp}
          color="#9EA3A9"
        />
      </Line>
      <Phone>
        <a href="tel:010-8937-2647">010-8937-2647</a>
      </Phone>
      <Info>
        <span>상호:포세이돈슈퍼민박</span>
        <span>대표자:이주</span>
        <span>사업자등록번호:310-03 -40934</span>
        <span>통신판매번호:제2021-충남태안-0000호</span>
        <span>주소:충남 태안군 원북면 옥파로 1152</span>
      </Info>
      <AdminLogin>
        {isLoggedIn ? (
          <button onClick={logUserOut}>로그아웃</button>
        ) : (
          <button onClick={() => setModal(true)}>관리자 로그인</button>
        )}
      </AdminLogin>
      {modal && <Modal setModal={setModal} />}
      <Copyright>
        <span>Copyright&copy;Poseidon</span>
        <span>Develope by WillisKim</span>
      </Copyright>
    </Wrapper>
  );
};

export default Footer;
