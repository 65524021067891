import styled from "styled-components";
import PageHeader from "../../components/page/PageHeader";
import routes from "../../routes";
import hakampo from "../../assets/images/travel/hakampo.jpg";
import mondong from "../../assets/images/travel/mondong.jpg";
import dungwong from "../../assets/images/travel/dungwong.jpg";
import sindurisagu from "../../assets/images/travel/sindurisagu.jpg";
import bara from "../../assets/images/travel/bara.jpg";
import gurepo from "../../assets/images/travel/gurepo.jpg";
import Fade from "react-reveal/Fade";

const Wrapper = styled.section`
  padding: 15vh 0 25vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Travels = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 1120px; */
  padding: 0vh 1vw;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;
const Travel = styled.div`
  padding: 10vh 1vw 0vh 1vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const Title = styled.div`
  color: #545354;
  font-size: 1.1rem;
  align-self: flex-start;
  h2 {
    font-family: "Naum Gothic";
    margin: 2vh 0;
  }
`;
const PhotoWrapper = styled.div`
  /* height: 70%; */
`;
const Photo = styled.img`
  width: 100%;
  height: 45vh;
  margin-top: 2vh;
`;
const Info = styled.div`
  font-size: 0.75rem;
  font-family: "Naum Gothic";
  h5 {
    margin: 4vh 0;
    font-weight: 600;
  }
  p {
    line-height: 1.4;
  }
`;

const Recommend = () => {
  const travels = [
    {
      title: "학암포해수욕장",
      address: " - 충남 태안군 원북면 방갈리 515-121",
      info: (
        <p>
          에메랄드 빛의 바다와 넓은 백사장, 그리고 붉게 핀 해당화가 감성을
          자극하는 곳. 학암포 해변은 썰물 때 드러나는 바위의 형상이 마치 학처럼
          보인다고 해서 붙여진 이름이다. 명물 학바위(학암)를 중심으로 W자 모양의
          해변이 조성되어 있다. 태안해안국립공원의 가장 북쪽에 위치한 이곳
          앞바다는 대뱅이, 굴뚝뱅이 등 이름만 들어도 재미난 섬들로 가득하다.
          해질 무렵, 바다와 섬들이 한데 어우러져 장관을 이룬다. 일몰 풍경이
          아름답기로 유명한 태안에서 가히 손꼽히는 절경이다. 해변 옆에
          태안해안국립공원이 관리하는 오토캠핑장이 자리해 해수욕과 갯벌체험,
          일몰 감상, 바다낚시 등을 여유롭게 즐기며 휴양할 수 있다. 여름이면 특히
          해안가에 지천으로 흐드러진 붉은 해당화와 자줏빛 순비가 꽃이 총천연색의
          물결을 이루는 이곳은 학암포 해변이다.
        </p>
      ),
      photo: hakampo,
    },
    {
      title: "먼동해수욕장",
      address: " - 충남 태안군 원북면 황촌리",
      info: (
        <p>
          구례포해변을 지나 30분정도 걸어가면 먼동해변을 만날 수 있다.
          먼동해변은 본래 해녀마을 해수욕장으로 불리웠으나 드라마 '먼동'
          촬영지로 알려지면서 먼동해수욕장으로 불리워지게 된곳이다. 이곳의
          해변앞으로 꼬깔섬 사이로 떨어지는 일몰이 장관을 이뤄 매년
          11월~12월경이면 이를 담기위해 전국 각지에서 촬영객들과 여행객들이
          모여드는 곳이기도 하다. 먼동해변은 다른 해변과 마찬가지로 갯바위형성이
          잘 되어 있어 갯바위낚시로도 적합하며 해녀마을 이름그대로 바다에
          어족자원들이 풍부한 곳이기도 하다. 먼동해변의 먼동전망대에서 오르면
          먼동해변과 그 주변의 아름다운 풍경을 한눈에 감상할 수 있다.
        </p>
      ),
      photo: mondong,
    },
    {
      title: "두웅습지",
      address: " - 충남 태안군 원북면 신두리 1351-15",
      info: (
        <p>
          세계에서 가장 작은 습지이자, 사구 배후습지로 7천년 전부터의 역사를
          간직해온 곳이 있다. 신두리해안사구 남쪽 배후에 위치한 두웅습지다.
          두웅습지에는 200여종의 식물과 10여 종의 양서류, 30여 종의 곤충이
          서식하고 있다. 이 가운데 희귀 야생동식물의 서식처로서 텃새인
          황조롱이와 천연기념물 323호 불은배새매, 멸종위기종 2급인 금개구리와
          맹꽁이 등이 이곳에 터전을 마련하고 있다. 노랑부리백로와 이끼도롱뇽을
          비롯해 다른 곳에서 보기 힘든 생물들이 새롭게 발견되는 등 생태적 가치를
          인정받아 2007년 람사르습지로 지정되어 생태보존지역으로 보호되고 있다.
        </p>
      ),
      photo: dungwong,
    },
    {
      title: "신두리사구센터",
      address: " - 충남 태안군 원북면 신두리",
      info: (
        <p>
          신두리해안사구 입구에 만들어진 신두리사구센터는 신두리 사구 생태공원
          안에 있는 각종 동식물과 해안사구에 대한 정보를 입체와 영상으로 재현해
          놓은 공간이다. 박물관 전시실은 흥미,체험,교육,효율등이 접목된
          자연생태계를 그대로 간직하고 있는 신두사구 생태를 그대로 연출해
          놓았다. 특히 박물관은 해설사와 관람객들이 최적의 관람환경에서 공간적
          구성요소를 고려한 동선과 관람자와 정보교환이 가능한 디지털 매체를
          적용하여 직접 보고 만지고 느끼는 체험위주의 전시시설로 꾸며졌다. 또한
          해안사구의 특징과 형성과정 및 신두사구에 서식하고 있는 동식물의 생태를
          집약해 놓은 테마의 컨텐츠 구성하였다.
        </p>
      ),
      photo: sindurisagu,
    },
    {
      title: "바라길",
      address: " - [구간] 학암포-신두리  [거리] 12km  [시간] 4시간",
      info: (
        <p>
          바다의 고어인 ‘아라’에서 그 명칭이 유래된 바라길은 학암포 - 구례포 -
          먼동 - 신두리로 이어지는 구간으로 싱그러운 바다 내음을 느낄 수 있는
          코스입니다. 바라길의 시점인 학암포자연관찰로에서는 셀프가이드가
          가능하도록 다양한 동․식물을 소개하는 해설안내판이 설치되어 있어
          아이들을 동반한 가족단위 탐방객에게 추천할 만한 코스입니다
        </p>
      ),
      photo: bara,
    },
    {
      title: "구례포해수욕장",
      address: " - 충남 태안군 원북면 황촌리 810-6",
      info: (
        <p>
          학암포 해변인근에 위치한 구례포 해변은 반달 모양으로 길게 뻗은 아담한
          해안선이 일품이다. 구한말 격동의 시대 민초들의 아픔을 극화한 KBS 사극
          [먼동]과 이성계의 조선 건국을 소재로 꾸민 사극 [용의 눈물]의 촬영지로
          유명하다. 한 폭의 풍경화처럼 아름다운 해변을 감상하기 위해 관광객들의
          발길이 꾸준히 이어지고 있는 곳으로 우럭, 광어, 해삼, 전복 등이 풍부해
          신선한 해산물을 맛볼 수도 있다.
        </p>
      ),
      photo: gurepo,
    },
  ];
  return (
    <Wrapper>
      <PageHeader
        title="Travel"
        subTitle="추천여행지"
        navList={[{ nav: "Travel", url: routes.recommend }]}
      />
      <Travels>
        {travels.map((travel, index) => (
          <Fade key={index} up cascade duration={1500}>
            <Travel>
              <Title>
                <h3>TAEAN TOUR #{index}</h3>
                <h2>{travel.title}</h2>
              </Title>
              <PhotoWrapper>
                <Photo src={travel.photo} />
              </PhotoWrapper>
              <Info>
                <h5>{travel.address}</h5>
                {travel.info}
              </Info>
            </Travel>
          </Fade>
        ))}
      </Travels>
    </Wrapper>
  );
};

export default Recommend;
