import styled from "styled-components";
import PageHeader from "../components/page/PageHeader";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router";
import ocean2 from "../assets/images/ocean/ocean-2.jpg";
import ocean4 from "../assets/images/ocean/ocean-4.jpg";
import glow2 from "../assets/images/glow2.jpg";
import barbecue1 from "../assets/images/barbecue.jpg";
import barbecue2 from "../assets/images/barbecue2.jpg";
import sea1 from "../assets/images/sea/sea-1.jpg";
import sea3 from "../assets/images/sea/sea-3.jpg";
import sea5 from "../assets/images/sea/sea-5.jpg";
import fishing1 from "../assets/images/fishing/fishing-1.jpg";
import fishing2 from "../assets/images/fishing/fishing-2.jpg";
import fishing3 from "../assets/images/fishing/fishing-3.jpg";
import squib1 from "../assets/images/squib/squib-1.gif";
import squib2 from "../assets/images/squib/squib-2.jpg";
import squib3 from "../assets/images/squib/squib-3.jpg";
import super1 from "../assets/images/super/super-1.jpg";
import super2 from "../assets/images/super/super-2.jpg";
import super3 from "../assets/images/super/super-3.jpg";

const Wrapper = styled.section`
  padding: 15vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.section`
  margin: 15vh 0 10vh 0;
  padding: 0 2vw;
  width: 100%;
  /* padding: 0 5vw; */
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 850px) {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 20px;
  }
`;
const Row2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #8c8c8c;
  p {
    font-weight: 500 !important;
  }
  div {
    padding: 0 5vw;
    @media only screen and (max-width: 850px) {
      text-align: center;
    }
  }
  @media only screen and (max-width: 850px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Intro = styled.div`
  color: #545354;
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
  h2 {
    font-size: 1.9rem;
    margin-bottom: 8vh;
    text-transform: uppercase;
  }
  p {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.7;
    font-family: "Naum Gothic", sans-serif;
    b {
      color: black;
    }
  }
`;
const Photo1 = styled.img`
  width: 70%;
  z-index: 10;
  position: relative;
  margin-left: 10px;
  @media only screen and (max-width: 850px) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
  }
`;
const Photo2 = styled.img`
  width: 55%;
  transform: translate(0, -15vh);
  @media only screen and (max-width: 850px) {
    width: 100%;
    margin-bottom: 30px;
    transform: translate(0, 0px);
  }
`;
const Photo3 = styled.img`
  width: 100vw;
  height: 80vh;
`;

const Specials = (props) => {
  const {
    match: { params },
  } = props;
  const history = useHistory();
  const specials = [
    {
      title: "ocean",
      subTitle: "바다전망",
      photos: [ocean2, ocean4, glow2],
      intro: [
        <>
          <p>
            학암포 포세이돈과 함께 아름다운 바다와 노을을 감상하실 수 있습니다.
          </p>
          <br />
          <p>마음을 담아서</p>
          <p>행복한 시간을 보내실 여러분을 초대합니다.</p>
        </>,
        <>
          <p>
            A day in a fantastic southern sea and a place where stars shine!!!
          </p>
          <p>
            Join poseidon when you want to allow yourself to relax in your daily
            routine.
          </p>
        </>,
      ],
    },
    {
      title: "barbecue",
      subTitle: "바베큐",
      photos: [barbecue1, barbecue2, barbecue2],
      intro: [
        <>
          <p>
            - 바베큐 이용시 그릴+숯 대여료는 / 3~4인 20,000원 / 그외 1인당
            5,000원 추가됩니다.
          </p>
          <p> - 바베큐장 이용시간 : 오후5시 ~ 10시</p>
          <p> - 객실 내 고기, 새우, 생선류는 취사 불가 입니다.</p>
          <p> - 객실 내 버너 사용금지</p>
          <p> - 개인 숯 화로장비나 버너는 사용금지입니다.</p>
          <p>
            - 바베큐 이용시 자리예약은 불가합니다. 원하시는 곳 가셔서 앉아계셔야
            숯 셋팅을 해드릴 수 있으니 참고바랍니다.
          </p>
        </>,
        <>
          <p>
            A day in a fantastic southern sea and a place where stars shine!!!
          </p>
          <p>
            Join poseidon when you want to allow yourself to relax in your daily
            routine.
          </p>
        </>,
      ],
    },
    {
      title: "fishing super",
      subTitle: "낚시슈퍼",
      photos: [super2, super1, super3],
      intro: [
        <>
          <p> - 지렁이 소 4,000</p>
          <p> - 지렁이 대 5,000</p>
          <p> - 염장지렁이 6,000</p>
          <p> - 염장혼무시 11,000</p>
          <p> - 지그해드, 웜, 합사, 낚시대, 뽕돌 있습니다</p>
          <p> - 낚시대 대여는 따로 문의주시기 바랍니다.</p>
        </>,
        <>
          <p>
            A day in a fantastic southern sea and a place where stars shine!!!
          </p>
          <p>
            Join poseidon when you want to allow yourself to relax in your daily
            routine.
          </p>
        </>,
      ],
    },
    {
      title: "sea",
      subTitle: "갯벌체험",
      photos: [sea1, sea3, sea5],
      intro: [
        <>
          <p>갯벌체험</p>
          <p>
            - 펜션 바로 앞에 있는 학암포해수욕장 갯벌에서 맛조개, 비단조개,
            게등을 직접 잡으실 수 있답니다.
          </p>
          <p>- 여름밤에 갯벌에서 소라, 골뱅이등을 잡는 해루질도 즐겨보세요.</p>
        </>,
        <>
          <p>
            A day in a fantastic southern sea and a place where stars shine!!!
          </p>
          <p>
            Join poseidon when you want to allow yourself to relax in your daily
            routine.
          </p>
        </>,
      ],
    },
    {
      title: "squib",
      subTitle: "폭죽",
      photos: [squib1, squib2, squib3],
      intro: [
        <>
          <p>폭죽 세일</p>
          <p>폭죽 10연발, 15연발, 20연발, 30연발, 폭죽 세트</p>
          <p>다양한 폭죽이 있습니다.</p>
          <br />
          <p> 해변에서 다양한 폭죽을 터트려보세요</p>
          <p> 밤하늘이 더 아름다워집니다.</p>
        </>,
        <>
          <p>
            A day in a fantastic southern sea and a place where stars shine!!!
          </p>
          <p>
            Join poseidon when you want to allow yourself to relax in your daily
            routine.
          </p>
        </>,
      ],
    },
    {
      title: "fishing",
      subTitle: "낚시",
      photos: [fishing1, fishing3, fishing2],
      intro: [
        <>
          <p>출조항 : "학암포 항" (어초.침선 전문출조)</p>
          <p>출항지 주소 : 태안군 원북면 옥파로 1152번지</p>
          <p>
            (승선명부는 "포세이돈 낚시점"에서 작성합니다. 주민등록증 꼭 지참)
          </p>
          <br />

          <p>
            <b>"포세이돈펜션 무료숙박 알림"</b>
          </p>
          <p>
            포세이돈호를 이용해주시는 조사님들께 편의를 위하여 전날 무료숙박을
            제공해 드립니다.
          </p>
          <p>
            전날 편안하게 주무시고 낚시 하실수 있습니다." 무료숙박은 전날만
            가능합니다 "
          </p>
          <p>
            문의 : <a href="tel:010-3406-8924">010-3406-8924</a>
          </p>
          <p>
            <a
              href="http://poseidon.or.kr/index.php"
              target="_blank"
              rel="noreferrer"
            >
              http://poseidon.or.kr/index.php
            </a>
          </p>
        </>,
        <>
          <p>
            A day in a fantastic southern sea and a place where stars shine!!!
          </p>
          <p>
            Join poseidon when you want to allow yourself to relax in your daily
            routine.
          </p>
        </>,
      ],
    },
  ];

  const special = specials.find((item) => item.title === params.special);
  if (!special) history.push("/");
  const specialsNumber = specials.findIndex(
    (item) => item.title === params.special
  );
  const navList = specials.map((item) => {
    return { nav: item.title, url: `/specials/${item.title}` };
  });
  return special ? (
    <Wrapper>
      <PageHeader
        title={special.title}
        subTitle={specials[specialsNumber].subTitle}
        navList={navList}
      />
      <Content>
        <Fade up duration={2000} cascade>
          <Row>
            <Intro>
              <h2>{special.title}</h2>
              {specials[specialsNumber].intro[0]}
            </Intro>
            <Photo1 src={specials[specialsNumber].photos[0]} />
          </Row>
        </Fade>
        <Fade up duration={2000} cascade>
          <Row2>
            <Photo2 src={specials[specialsNumber].photos[1]} />
            <Intro>{specials[specialsNumber].intro[1]}</Intro>
          </Row2>
        </Fade>
      </Content>
      <Fade up duration={1500}>
        <Photo3 src={specials[specialsNumber].photos[2]} />
      </Fade>
    </Wrapper>
  ) : null;
};

export default Specials;
