import styled from "styled-components";
// import hakampo from "../assets/images/hakampo.jpg";
import sea3 from "../assets/images/sea3.jpg";

const Wrapper = styled.div`
  height: 914px;
  width: 100%;
  background: url(${sea3}) center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  @media only screen and (max-width: 1024px) {
    background-attachment: unset;
  }
  opacity: 0.9;
  position: relative;
`;

const OverWrite = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  p {
    font-size: 0.8rem;
    letter-spacing: 7px;
    margin-bottom: 10px;
  }
  h1 {
    letter-spacing: 4px;
    border: 900;
    font-size: 2.4rem;
    margin-bottom: 10px;
  }
  span {
    font-size: 0.6rem;
    margin-bottom: 15px;
  }
  a {
    background-color: #8f8f8f;
    border: none;
    color: white;
    padding: 1.2vh 3vw;
    font-size: 0.75rem;
    font-weight: 600;
    font-family: "Nanum Gothic", sans-serif;
    cursor: pointer;
  }
`;
const Bottom = () => (
  <Wrapper>
    <OverWrite>
      <p>welcome</p>
      <h1>Poseidon</h1>
      <span>Ocean View & Pension</span>
      <a
        href="https://booking.ddnayo.com/booking-calendar-status?accommodationId=11003&channelCode=0010"
        target="_blank"
        rel="noreferrer"
      >
        예약하기
      </a>
    </OverWrite>
  </Wrapper>
);

export default Bottom;
