import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  faChevronUp,
  faFont,
  faQuestion,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { isLoggedInVar } from "../../apollo";
import PageHeader from "../../components/page/PageHeader";
import routes from "../../routes";

const SQuestion = styled.div`
  padding: 15vh 0vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Wrapper = styled.div`
  padding: 10vh 1vw;
  width: 100%;
  font-family: "Nanum Gothic";
`;
const Options = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TotalPage = styled.span`
  background: #d4d4d4;
  padding: 1.5vh 1vw;
  font-size: 0.8rem;
  border-radius: 0.2rem;
`;
const Faqs = styled.ul`
  margin-top: 3vh;
  border-top: 1px solid #a6a6a6;
`;
const Faq = styled.li``;
const QuestWrapper = styled.div`
  padding: 3vh 5vw;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const QuestColumn = styled.div`
  display: flex;
  align-items: center;
  svg {
    transition: all 0.5s;
    &:not(:first-child) {
      margin-left: 1vw;
    }
  }
`;
const Quest = styled.div`
  display: flex;
  align-items: center;
  i {
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 0.8rem 1rem;
  }
  span {
    margin: 0 3vw;
    font-size: 1.6rem;
    @media only screen and (max-width: 700px) {
      font-size: 1rem;
    }
  }
`;

const AnswerWrapper = styled.div`
  padding: 0vh 5vw;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  height: 0;
  overflow: hidden;
  transition: all 0.8s;
  i {
    padding: 0 1.5vw;
  }
`;
const Answer = styled.div`
  margin: 0 3vw;
  line-height: 1rem;
  @media only screen and (max-width: 700px) {
    font-size: 0.8rem;
  }
`;
const FaqLink = styled(Link)`
  border: 0;
  background: #ccc;
  padding: 1vh 1vw;
  font-size: 0.8rem;
  border-radius: 0.2rem;
  color: white;
  cursor: pointer;
  text-align: center;
`;
const Search = styled.form`
  display: flex;
  width: 50%;
  min-width: 310px;
  align-items: center;
  margin-top: 2vh;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  select {
    height: 100%;
    padding: 2vh 7px;
    border: 0;
    border-right: 1px solid #ddd;
  }
  button {
    all: unset;
    margin-right: 2vw;
    cursor: pointer;
  }
`;
const Input = styled.input`
  width: 100%;
  padding: 2vh 7px;
  border: 0.5px solid ${(props) => (props.hasErr ? "tomato" : "white")};
  border-radius: 3px;
  font-size: 0.9rem;
  outline: none;
`;
const FormError = styled.span`
  color: tomato;
  font-size: 0.7rem;
  margin: 0.5vh 0 1vh 0;
  text-align: center;
  font-weight: 600;
`;
const InputBtn = styled(Link)`
  background: #d4d4d4;
  padding: 1.5vh 1vw;
  font-size: 0.8rem;
  border-radius: 0.2rem;
`;
const FAQS_QUERY = gql`
  query seeFaqs($scope: String, $term: String) {
    seeFaqs(scope: $scope, term: $term) {
      id
      question
      answer
    }
  }
`;
const FAQ_DELETE = gql`
  mutation deleteFaq($id: Int!) {
    deleteFaq(id: $id) {
      ok
      error
    }
  }
`;

const Question = () => {
  const navList = [
    { nav: "예약안내", url: routes.guide },
    {
      nav: "실시간예약",
      url: "https://booking.ddnayo.com/booking-calendar-status?accommodationId=11003&channelCode=0010",
    },
    { nav: "공지사항", url: routes.notices },
    { nav: "자주묻는질문", url: routes.questions },
  ];
  const [open, setOpen] = useState(true);
  const onOpen = (e) => {
    setOpen(!open);

    if (open) {
      e.currentTarget.querySelector("#arrow").style.transform =
        "rotate(180deg)";
      e.currentTarget.nextSibling.style.padding = "3vh 5vw";
      e.currentTarget.nextSibling.style.height = "auto";
      e.currentTarget.nextSibling.style.opacity = "1";
    } else {
      e.currentTarget.querySelector("#arrow").style.transform =
        "rotate(360deg)";
      e.currentTarget.nextSibling.style.padding = "0vh 5vw";
      e.currentTarget.nextSibling.style.height = "0";
      e.currentTarget.nextSibling.style.opacity = "0";
    }
  };
  const { data, refetch } = useQuery(FAQS_QUERY);
  const [deleteFaq] = useMutation(FAQ_DELETE);
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  useEffect(() => refetch());
  const onDelete = async (id) => {
    const check = window.confirm("정말 삭제하시겠습니까?");
    if (!check) return;
    const {
      data: {
        deleteFaq: { ok, error },
      },
    } = await deleteFaq({ variables: { id } });
    if (!ok) setError("result", { message: error });
    if (ok) return refetch();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({ mode: "onBlur" });
  const onSerchSubmit = (params) => {
    refetch({ ...params });
  };
  return (
    <SQuestion>
      <PageHeader title="FAQ" subTitle="자주묻는질문" navList={navList} />
      <Wrapper>
        <Options>
          <TotalPage>Total {data?.seeFaqs.length}건 1 페이지</TotalPage>
          {isLoggedIn && (
            <InputBtn to={routes.question_input}>질문 입력</InputBtn>
          )}
        </Options>
        <Faqs>
          {data?.seeFaqs.map((faq, index) => (
            <Faq key={index}>
              <QuestWrapper onClick={onOpen}>
                <QuestColumn>
                  <Quest>
                    <i>
                      <FontAwesomeIcon
                        icon={faQuestion}
                        size="2x"
                        color="#3498db"
                      />
                    </i>
                    <span>{faq.question}</span>
                  </Quest>
                </QuestColumn>
                <QuestColumn>
                  {isLoggedIn && (
                    <FontAwesomeIcon
                      onClick={() => onDelete(faq.id)}
                      icon={faTrash}
                      size="lg"
                      color="#ccc"
                    />
                  )}
                  <FontAwesomeIcon
                    id="arrow"
                    icon={faChevronUp}
                    size="lg"
                    color="#ccc"
                  />
                </QuestColumn>
              </QuestWrapper>
              <AnswerWrapper>
                <i>
                  <FontAwesomeIcon icon={faFont} size="2x" color="#3498db" />
                </i>
                <Answer>{faq.answer}</Answer>
                <FaqLink to={`/reservation/question/${faq.id}`}>
                  자세히보기
                </FaqLink>
              </AnswerWrapper>
            </Faq>
          ))}
        </Faqs>
        <Search onSubmit={handleSubmit(onSerchSubmit)}>
          <select {...register("scope")}>
            <option>제목</option>
            <option>내용</option>
            <option>제목+내용</option>
          </select>
          <Input
            {...register("term", {
              minLength: { value: 3, message: "세글자 이상 입력하세요" },
            })}
            placeholder="검색어를 입력해주세요"
          />
          <button type="submit">
            <FontAwesomeIcon icon={faSearch} size="lg" />
          </button>
        </Search>
        {errors.term && <FormError>{errors.term.message}</FormError>}
        {errors.result && <FormError>{errors.result.message}</FormError>}
      </Wrapper>
    </SQuestion>
  );
};

export default Question;
