import React from "react";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  padding: 1vh 1vw 10vh 1vw;
  font-family: "Nanum Gothic";
  width: 100%;
  table {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 0.75rem;
    border: 2px solid #ddd;
    th,
    td {
      border: 1px solid #ddd;
      padding: 10px 0;
      vertical-align: middle;
    }
    th {
      background-color: #fafafa;
    }
  }
`;

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   setGlobalFilter(event.target.elements.filter.value);
  // };

  return (
    <Wrapper>
      {/* <form onSubmit={handleSubmit}>
        <input name="filter" />
        <button>Search</button>
      </form> */}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faArrowDown} color="grey" />
                      ) : (
                        <FontAwesomeIcon icon={faArrowUp} color="grey" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.id === "title")
                    return (
                      <td style={{ width: "50%" }} {...cell.getCellProps()}>
                        <Link
                          to={`/reservation/notice/${cell.row.values.index}`}
                          style={{ padding: "0 20%" }}
                        >
                          {cell.render("Cell")}
                        </Link>
                      </td>
                    );
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Wrapper>
  );
}

export default Table;
