import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import premium from "../assets/images/101/1011.jpg";
import sweet from "../assets/images/202/202-1.jpg";
import delux from "../assets/images/203/203-1.jpg";
import Fade from "react-reveal/Fade";
import barbecue from "../assets/images/barbecue.jpg";
import sea4 from "../assets/images/sea4.jpg";
import super3 from "../assets/images/super/super-3.jpg";
import squib1 from "../assets/images/squib/squib-1.gif";
import fishing2 from "../assets/images/fishing/fishing-2.jpg";

const Wraaper = styled.section``;
const Rooms = styled.section`
  /* height: 130vh; */
  padding: 180px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const RommsTitme = styled.div`
  text-transform: uppercase;
  font-family: "Nanum Gothic", sans-serif;
  text-align: center;
  h3 {
    font-size: 1.1rem;
    margin-bottom: 25px;
  }
  h1 {
    font-size: 2rem;
    margin-bottom: 15px;
  }
  p {
    font-size: 0.7rem;
    margin-bottom: 100px;
  }
`;
const Photos = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  height: 50vh;
  @media only screen and (max-width: 900px) {
    width: 100%;
    height: 1100px;
    flex-wrap: wrap;
  }
`;
const Photo = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 50px;
  div {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    bottom: -40px;
    padding: 20px;
    text-align: right;
    background: #fff;
    color: black;
    z-index: 2;
    font-size: 0.9rem;
    border-radius: 3px;
    overflow: visible;
    span {
      margin-top: 7px;
      font-size: 0.1em;
      text-transform: uppercase;
    }
  }
`;
const Img = styled.img`
  height: 50vh;
  width: 100%;
  border-radius: 2px;
`;
const Specials = styled.section`
  /* height: 120vh; */
  padding: 0vh 2vw 17vh 2vw;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  overflow: hidden;
  @media only screen and (max-width: 560px) {
    flex-direction: column-reverse;
  }
`;
const SpecialsContent = styled.div`
  display: flex;
  flex-direction: column;
  color: #545354;
  @media only screen and (max-width: 560px) {
    flex-direction: row;
    margin-top: 30px;
  }
  h3 {
    width: 10%;
    font-size: 1.7rem;
    margin-bottom: 20px;
  }
  p {
    font-size: 0.7rem;
    &:last-child {
      margin-bottom: 40px;
    }
  }
  ul {
    font-family: "Nanum Gothic", sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    li {
      margin-bottom: 5px;
    }
  }
`;
const SpecialsTitle = styled.div``;
const SpecialsList = styled.div``;
const SpecialsPhoto = styled.div`
  width: 70%;
  /* height: 100%; */
  /* display: flex; */
  @media only screen and (max-width: 560px) {
    width: 100%;
  }
`;
const SpecialsImg = styled.img`
  width: 759px;
  height: 596px;
`;

const Home = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    // variableWidth: true,
    // responsive: [
    //   {
    //     breakpoint: 900,
    //     settings: {
    //       infinite: true,
    //       slidesToShow: 2,
    //     },
    //   },
    // ],
  };
  return (
    <Wraaper>
      <Rooms>
        <RommsTitme>
          <Fade up>
            <h3>Poseidon pension</h3>
            <h1>Rooms</h1>
            <p>Ocean View & Modern pension</p>
          </Fade>
        </RommsTitme>
        <Photos>
          {/* <Slider {...settings} style={{ width: "100%" }}> */}
          <Photo>
            <Fade up>
              <Img src={sweet} alt="" />
              <div>
                Sweet ROOM
                <span>more view</span>
              </div>
            </Fade>
          </Photo>
          <Photo>
            <Fade up>
              <Img src={delux} alt="" />
              <div>
                Delux ROOM
                <span>more view</span>
              </div>
            </Fade>
          </Photo>
          <Photo>
            <Fade up>
              <Img src={premium} alt="" />
              <div>
                Delux ROOM
                <span>more view</span>
              </div>
            </Fade>
          </Photo>
          {/* </Slider> */}
        </Photos>
      </Rooms>
      <Specials>
        <Fade up>
          <SpecialsContent>
            <SpecialsTitle>
              <h3>POSEIDON SPECIAL</h3>
              <p>BEAUTIFUL OCEANVIEW</p>
              <p>MODERN COMFORTABLE</p>
            </SpecialsTitle>
            <SpecialsList>
              <ul>
                <li>#해변</li>
                <li>#바베큐</li>
                <li>#슈퍼</li>
                <li>#갯벗체험</li>
                <li>#폭죽</li>
                <li>#낚시</li>
              </ul>
            </SpecialsList>
          </SpecialsContent>
        </Fade>
        <SpecialsPhoto>
          <Slider {...settings}>
            <SpecialsImg src={sea4} alt="" />
            <SpecialsImg src={barbecue} alt="" />
            <SpecialsImg src={super3} alt="" />
            <SpecialsImg src={squib1} alt="" />
            <SpecialsImg src={fishing2} alt="" />
          </Slider>
        </SpecialsPhoto>
      </Specials>
    </Wraaper>
  );
};
export default Home;
