import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import routes from "./routes";
import Home from "./screens/Home";
import Rooms from "./screens/Rooms";
import Direction from "./screens/direction/Direction";
import Prologue from "./screens/prologue/Prologue";
import Guide from "./screens/reservation/Guide";
import Notices from "./screens/reservation/Notices";
import NoticeInput from "./screens/reservation/NoticeInput";
import Questions from "./screens/reservation/Questions";
import Recommend from "./screens/travels/Recommend";
import Layout from "./components/Layout";
import { GlobalStyle } from "./styles";
import Specials from "./screens/Specials";
import { ApolloProvider, useReactiveVar } from "@apollo/client";
import { client, isLoggedInVar } from "./apollo";
import Notice from "./screens/reservation/Notice";
import { RecoilRoot } from "recoil";
import Question from "./screens/reservation/Question";
import QuestionInput from "./screens/reservation/QuestionInput";

function App() {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  return (
    <ApolloProvider client={client}>
      <RecoilRoot>
        <Router>
          <GlobalStyle />
          <Layout>
            <Switch>
              <Route exact path={routes.home} component={Home} />
              <Route path={routes.prologue} component={Prologue} />
              <Route path={routes.rooms} component={Rooms} />
              <Route path={routes.specials} component={Specials} />
              <Route path={routes.recommend} component={Recommend} />
              <Route path={routes.direction} component={Direction} />
              <Route path={routes.guide} component={Guide} />
              <Route path={routes.notices} component={Notices} exact />
              {isLoggedIn && (
                <Route
                  path={routes.notice_input}
                  component={NoticeInput}
                  exact
                />
              )}
              <Route path={routes.notice} component={Notice} exact />
              <Route path={routes.questions} component={Questions} exact />
              {isLoggedIn && (
                <Route
                  path={routes.question_input}
                  component={QuestionInput}
                  exact
                />
              )}
              <Route path={routes.question} component={Question} exact />
              {/* <Route component={NotFound} /> */}
              <Redirect from="*" to="/" />
            </Switch>
          </Layout>
        </Router>
      </RecoilRoot>
    </ApolloProvider>
  );
}

export default App;
