import styled from "styled-components";
import PageHeader from "../../components/page/PageHeader";
import hakampo4 from "../../assets/images/hakampo4.jpg";
import glow2 from "../../assets/images/glow2.jpg";
import sea3 from "../../assets/images/sea3.jpg";
import Fade from "react-reveal/Fade";
import routes from "../../routes";

const Wrapper = styled.section`
  /* height: 120vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15vh 0;
`;
const Content = styled.section`
  margin: 15vh 0 10vh 0;
  padding: 0 2vw;
  width: 100%;
  /* padding: 0 5vw; */
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 850px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
const Row2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #8c8c8c;
  div {
    padding: 0 5vw;
    @media only screen and (max-width: 850px) {
      text-align: center;
    }
  }
  @media only screen and (max-width: 850px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Intro = styled.div`
  color: #545354;
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
  h2 {
    font-size: 1.7rem;
    margin-bottom: 5vh;
  }
  p {
    font-size: 0.75rem;
    line-height: 1.7;
    font-family: "Naum Gothic", sans-serif;
  }
`;
const Photo1 = styled.img`
  width: 70%;
  margin-left: 10px;
  @media only screen and (max-width: 850px) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
  }
`;
const Photo2 = styled.img`
  width: 55%;
  transform: translate(0, -50px);
  @media only screen and (max-width: 850px) {
    width: 100%;
    margin-bottom: 30px;
    transform: translate(0, 0px);
  }
`;
const Photo3 = styled.img`
  width: 100%;
  height: 80vh;
`;

const Prologue = () => (
  <Wrapper>
    <PageHeader
      title="Prologue"
      subTitle="풍경보기"
      navList={[{ nav: "Prologue", url: routes.prologue }]}
    />
    <Content>
      <Fade up duration={2000} cascade>
        <Row>
          <Intro>
            <h2>PROLOGUE</h2>
            <p>푸른 물결 넘실대는 수평선이 펼쳐진 곳...</p>
            <p>황금빛 모래가 반짝이는 곳...</p>
            <p>파도 소리에 갈매기도 춤추는 곳...</p>
            <p>방안 가득 따스한 햇살이 머무는 곳...</p>
            <p>붉은 노을이 마음 따뜻하게 하는 곳...</p>
            <p>밤하늘 촘촘히 별들이 노래하는 곳...</p>
            <p>행복한 웃음이 번지는 곳...</p>
            <p>환상의 섬 안면도앞 바닷가 펜션...</p>
            <p>골든웨이브입니다.</p>
            <br />
            <p>마음을 담아서</p>
            <p>행복한 시간을 보내실 여러분을 초대합니다.</p>
          </Intro>
          <Photo1 src={sea3} />
        </Row>
      </Fade>
      <Fade up duration={2000} cascade>
        <Row2>
          <Photo2 src={hakampo4} />
          <Intro>
            <p>A beach pension in front of Anmyeondo Island...Golden Wave.</p>
            <p>We invite you to have a happy time with all your heart.</p>
          </Intro>
        </Row2>
      </Fade>
    </Content>
    <Fade up duration={1500}>
      <Photo3 src={glow2} />
    </Fade>
  </Wrapper>
);

export default Prologue;
