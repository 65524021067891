import { Fragment } from "react";
import styled from "styled-components";
import PageHeader from "../../components/page/PageHeader";
import routes from "../../routes";
import sweet1 from "../../assets/images/203/203-1.jpg";
import sweet2 from "../../assets/images/203/203-2.jpg";

const Wrapper = styled.section`
  padding: 15vh 0vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Reservation = styled.div`
  padding: 10vh 3vw;
  width: 96vw;
  /* display: flex;
  justify-content: center; */
  overflow-x: auto;

  table {
    width: 100%;
    font-family: "Nanum Gothic";
    font-size: 0.7rem;
    color: #808080;
    text-align: center;
    border-collapse: collapse;
    th {
      vertical-align: middle;
      font-weight: 900;
      background: #eeeeee;
    }
    th,
    td {
      vertical-align: middle;
      border: 1px solid #d2d2d2;
      padding: 0.5rem;
      border-collapse: collapse;
    }
  }
`;
const ReservationGuide = styled.div`
  display: flex;
  padding: 5vh 2vw;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;
const GuideImgWrapper = styled.div`
  width: 50%;
  img {
    max-width: 100%;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`;
const GuideIntro = styled.div`
  width: 50%;
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
  margin-left: 3vw;
  font-family: "Nanum Gothic";
  line-height: 1.6;
  h4 {
    font-family: "Sorts Mill Goudy", serif;
    font-size: 1.2rem;
    color: #545354;
    margin-bottom: 5px;
  }
  h5 {
    font-size: 1.2rem;
    margin-bottom: 3vh;
    font-weight: 600;
  }
  p {
    font-size: 0.8rem;
    letter-spacing: -1px;
    b {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
`;
const ReservationInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10vh 0vw;
`;

const InfoImgWrapper = styled.div`
  width: 100%;
  height: 60vh;
  overflow: hidden;
  img {
    max-width: 100%;
  }
`;
const InfoContent = styled.div`
  display: flex;
  font-family: "Nanum Gothic";
  padding: 10vh 0vw;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    & > div {
      width: 100%;
    }
  }
`;
const InfoAlertRefund = styled.div`
  width: 50%;
  padding: 0 1.5vw;
  line-height: 1.5;
  letter-spacing: -1px;
  h4 {
    font-size: 1.2rem;
    color: #545354;
    margin-bottom: 1vh;
  }
  h5 {
    font-size: 1.2rem;
    color: #545354;
    margin-bottom: 3vh;
  }
  p {
    font-size: 0.85rem;
    b {
      color: #bc0000;
    }
  }
`;
const RefundTable = styled.table`
  margin: 3vh 0;
  width: 100%;
  text-align: center;
  font-size: 0.85rem;
  th {
    background-color: #ebebeb;
  }
  td,
  th {
    border: 1px solid #dbdbdb;
    padding: 10px;
  }
`;

const Guide = () => {
  const navList = [
    { nav: "예약안내", url: routes.guide },
    {
      nav: "실시간예약",
      url: "https://booking.ddnayo.com/booking-calendar-status?accommodationId=11003&channelCode=0010",
    },
    { nav: "공지사항", url: routes.notices },
    { nav: "자주묻는질문", url: routes.questions },
  ];

  const tableRow = [
    [
      ["디럭스201(원룸)", "9평"],
      ["비수기", "70,000", "80,000", "100,000", "10,000"],
      ["준성수기", "100,000", "130,000", "130,000", "10,000"],
      ["성수기", "150,000", "180,000", "180,000", "10,000"],
      ["극성수기", "180,000", "180,000", "180,000", "10,000"],
    ],
    [
      ["디럭스202(원룸)", "9평"],
      ["비수기", "70,000", "80,000", "100,000", "10,000"],
      ["준성수기", "100,000", "130,000", "130,000", "10,000"],
      ["성수기", "150,000", "180,000", "180,000", "10,000"],
      ["극성수기", "180,000", "180,000", "180,000", "10,000"],
    ],
    [
      ["스위트203(원룸)", "10평"],
      ["비수기", "80,000", "90,000", "110,000", "10,000"],
      ["준성수기", "120,000", "150,000", "150,000", "10,000"],
      ["성수기", "160,000", "200,000", "200,000", "10,000"],
      ["극성수기", "200,000", "200,000", "200,000", "10,000"],
    ],
    [
      ["프리미엄101(투룸)", "15평"],
      ["비수기", "100,000", "110,000", "130,000", "10,000"],
      ["준성수기", "150,000", "180,000", "180,000", "10,000"],
      ["성수기", "200,000", "250,000", "250,000", "10,000"],
      ["극성수기", "250,000", "250,000", "250,000", "10,000"],
    ],
  ];
  return (
    <Wrapper>
      <PageHeader title="Reservation" subTitle="예약안내" navList={navList} />
      <Reservation>
        <table>
          <thead>
            <tr>
              <th rowSpan="2" style={{ width: "20%" }}>
                객실
              </th>
              <th rowSpan="2" style={{ width: "10%" }}>
                넓이(평)
              </th>
              <th
                rowSpan="2"
                style={{ width: "10%", backgroundColor: "#F8F1E9" }}
              >
                시즌
              </th>
              <th rowSpan="2" style={{ width: "10%" }}>
                주중
              </th>
              <th rowSpan="2" style={{ width: "10%" }}>
                금요일
              </th>
              <th rowSpan="2" style={{ width: "10%" }}>
                주말공휴일전날
              </th>
              <th
                colSpan="3"
                style={{
                  width: "15%",
                  backgroundColor: "#F8F1E9",
                }}
              >
                기준인원초과요금
              </th>
            </tr>
            <tr>
              <th
                style={{
                  backgroundColor: "#F8F1E9",
                }}
              >
                성인
              </th>
              <th
                style={{
                  backgroundColor: "#F8F1E9",
                }}
              >
                아동
              </th>
              <th
                style={{
                  backgroundColor: "#F8F1E9",
                }}
              >
                유아
              </th>
            </tr>
          </thead>
          <tbody>
            {tableRow.map((item, index) => (
              <Fragment key={index}>
                <tr>
                  <td rowSpan="4">{item[0][0]}</td>
                  <td rowSpan="4">{item[0][1]}</td>
                  <td>{item[1][0]}</td>
                  <td>{item[1][1]}</td>
                  <td>{item[1][2]}</td>
                  <td>{item[1][3]}</td>
                  <td colSpan="3">{item[1][4]}</td>
                </tr>
                <tr>
                  <td>{item[2][0]}</td>
                  <td>{item[2][1]}</td>
                  <td>{item[2][2]}</td>
                  <td>{item[2][3]}</td>
                  <td colSpan="3">{item[2][4]}</td>
                </tr>
                <tr>
                  <td>{item[3][0]}</td>
                  <td>{item[3][1]}</td>
                  <td>{item[3][2]}</td>
                  <td>{item[3][3]}</td>
                  <td colSpan="3">{item[3][4]}</td>
                </tr>
                <tr>
                  <td>{item[4][0]}</td>
                  <td>{item[4][1]}</td>
                  <td>{item[4][2]}</td>
                  <td>{item[4][3]}</td>
                  <td colSpan="3">{item[4][4]}</td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </Reservation>
      <ReservationGuide>
        <GuideImgWrapper>
          <img src={sweet1} alt="" />
        </GuideImgWrapper>
        <GuideIntro>
          <h4>RESERVATION GUIDE</h4>
          <h5>예약안내</h5>
          <p> - 예약방법 : 실시간 + 전화(상담시간 : 오전 9시 ~ 오후 9시까지)</p>
          <p>
            - 예약전화 : <b>010-8937-2647</b>
          </p>
          <p>
            - 예약계좌 : <b>010-7385-1304</b> 기업 김성환
          </p>
          <br />
          <p> - 성수기, 준성수기 기간 : 실시간예약 달력을 참고하세요.</p>
          <p> - 애완동물과 함께 입실하실 수 없습니다.</p>
          <p>
            - 객실 내 휴대폰 충전기가 없습니다. 휴대폰 개인충전기
            지참부탁드립니다.
          </p>
          <p> - 이불은 2인에 1침구입니다.</p>
          <p>
            - 저희 펜션을 이용하시고 숙박하시는 고객님 일행분들께서 취침을
            안하셔도 추가인원 1인당 10,000원 추가요금 발생됩니다.
          </p>
          <p> - 입실 전 냉장고 사용은 불가입니다.</p>
          <br />
          <p> - 기준인원 초과시 1인당 10,000원 추가요금됩니다.(12개월이상)</p>
          <p>
            - 준성수기, 성수기, 극성수기는 인원추가요금 1인당
            10,000원(12개월이상)
          </p>
          <br />
          <p>
            - 바베큐 이용시 그릴+숯 대여료는 3~4인 20,000원 / 그외 1인당 5,000원
            추가됩니다.
          </p>

          <p> - 바베큐장 이용시간 : 입실시간 ~ 10시</p>
          <p> - 객실 내 고기, 새우, 생선류는 취사 불가 입니다.</p>
          <p> - 객실 내 버너 사용금지</p>
          <p> - 개인 숯 화로장비나 버너는 사용금지입니다.</p>
          <p>
            - 바베큐 이용시 자리예약은 불가합니다. 원하시는 곳 가셔서 앉아계셔야
            숯 셋팅을 해드릴 수 있으니 참고바랍니다.
          </p>
        </GuideIntro>
      </ReservationGuide>
      <ReservationInfo>
        <InfoImgWrapper>
          <img src={sweet2} alt="" />
        </InfoImgWrapper>
        <InfoContent>
          <InfoAlertRefund>
            <h4>RESERVATION INFO</h4>
            <h5>유의사항</h5>
            <p>미성년자는 보호자 동반 없이 이용 하실 수 없습니다.</p>
            <p>
              애완동물은 타 객실 및 손님을 위해 입실을 금하오니 양해바랍니다.
            </p>
            <p>(동반 입실시 당일 예약취소에 해당됩니다.)</p>
            <p>객실 내에서는 절대 금연입니다.</p>
            <br />
            <p>01. 입금 및 예약확인 </p>
            <p>객실 요금 전액을 입금해주셔야 예약이 완료됩니다.</p>
            <p>
              입금 시 예약자와 입금자명이 다를 경우 필히 확인 전화 주십시오.
            </p>
            <p>
              실시간예약 달력으로 예약시 발송되는 문자(객실,투숙일,요금)를
              확인하세요.
            </p>
            <p>
              예약 후 12시간 이내(성수기 2시간 이내)로 입금이 안되면 예약이
              자동취소됩니다.
            </p>
            <br />
            <p>02. 최대인원 초과시</p>
            <p>
              <b>최대인원 초과시 입실 및 환불이 불가</b>합니다.
            </p>
            <p>1실 이상 예약은 사전 협의 후 예약하시기 바랍니다.</p>
            <br />
            <p>03. 입실시간 이용당일</p>
            <p>
              <b>14:00 ~ 22:00 까지</b>입니다.
            </p>
            <p>
              22시 이후 펜션에 도착하실 경우 미리 연락하셔야 하며,사전 연락이
              없을 경우 입실을 제한 받을 수 있습니다.
            </p>
            <br />
            <p>04. 퇴실시간</p>
            <p>
              <b>마지막 이용일 오전11시까지</b>입니다.
            </p>
            <p>다음 이용인을 위하여 퇴실시간을 준수해 주시기 바랍니다.</p>
            <br />
            <p>05. 객실정리</p>
            <p>객실정리 후 퇴실점검을 받으셔야 합니다.</p>
            <p>
              점검 후 객실키를 반납해 주세요. (객실 키 분실시 3만원의 비용이
              발생됩니다)
            </p>
            <p>이용중 집기 파손 시에는 펜션지기에게 알려주시기 바랍니다.</p>
            <p>쓰레기는 지정된 장소에 분리하여 주시기 바랍니다.</p>
            <br />
            <p> 06. 취사 및 바베큐</p>
            <p>
              오후 10시 이후의 바베큐장 이용 및 고성방가는 타 객실손님을 위하여
              금하오니 양해 바랍니다.
            </p>
            <p>
              객실내에서는 육류(삼겹살/튀김류) 구이를 금하오니 실외 지정된 장소
              및 바베큐장을 이용하시길 부탁드립니다.
            </p>
            <p>(화재 및 냄새로 인한 타손님 배려 차원입니다)</p>
          </InfoAlertRefund>
          <InfoAlertRefund>
            <h4>RESERVATION INFO</h4>
            <h5>환불규정</h5>
            <p>
              올바른 예약문화 정착을 위하여 당펜션에서는 예약 취소시 환불기준을
              아래와 같이 운영하고 있사오니
            </p>
            <p>꼭 확인을 하시고 예약해 주시기 바랍니다!</p>
            <p>환불액은 객실전체요금에서 환불됩니다.</p>
            <RefundTable>
              <thead>
                <tr>
                  <th>취소일기준</th>
                  <th>취소수수료</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>이용일7일전</td>
                  <td>90% 환불</td>
                </tr>
                <tr>
                  <td>이용일 6일 ~ 5일전</td>
                  <td>70% 환불</td>
                </tr>
                <tr>
                  <td>이용일 4일전</td>
                  <td>50%</td>
                </tr>
                <tr>
                  <td>이용일 3일전 ~ 당일 취소 시</td>
                  <td> 환불불가</td>
                </tr>
                <tr>
                  <td>이용일 7일전부터~ 당일 날짜변경 불가</td>
                  <td>예약취소후 다시 예약</td>
                </tr>
              </tbody>
            </RefundTable>
            <p>
              환불은 입금자명으로 되며 입금시 송금수수료는 제외한 후 입금됩니다.
            </p>
          </InfoAlertRefund>
        </InfoContent>
      </ReservationInfo>
    </Wrapper>
  );
};

export default Guide;
