import styled, { css, keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useMutation, gql } from "@apollo/client";
import { useForm } from "react-hook-form";
import { logUserIn } from "../apollo";

const ModalBg = styled.div`
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.75rem;
  width: 21%;
  min-width: 260px;
  background-color: white;
  border-radius: 17px;
  font-family: "Nanum Gothic";
`;
const ModalHeader = styled.div`
  width: 100%;
  padding: 8px 10px;
  margin-bottom: 5px;
  position: relative;
  font-size: 18px;
  display: flex;
  justify-content: center;
`;
const ModalTitle = styled.div``;
const CloseBtn = styled.button`
  position: absolute;
  top: -13px;
  right: 0px;
  border: 0;
  font-size: 17px;
  border-radius: 20px;
  background-color: white;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(1);
  }
`;
const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7px;
`;
const Form = styled.form`
  width: 90%;
  text-align: center;
`;
const Input = styled.input`
  width: 100%;
  padding: 2vh 7px;
  background-color: #fafafa;
  border: 0.5px solid
    ${(props) => (props.hasErr ? "tomato" : "rgb(219, 219, 219)")};
  border-radius: 3px;
  font-size: 12px;
  margin-top: 5px;
  box-sizing: border-box;
  &:focus {
    border-color: rgb(38, 38, 38);
  }
`;
const spinner = keyframes`
  from  { transform : rotate(0deg);}
  to { transform : rotate(360deg);}
`;

const SendBtn = styled.button`
  width: 100%;
  border: 0;
  border-radius: 3px;
  margin: 2vh 0 1vh 0;
  padding: 0.5vh 0;
  font-size: 0.8rem;
  background-color: #787878;
  color: white;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  svg {
    animation: ${(props) =>
      props.name === "login"
        ? css`
            ${spinner} 1s ease infinite
          `
        : null};
  }
`;
const FormError = styled.span`
  color: tomato;
  font-size: 0.7rem;
  margin: 0.5vh 0 1vh 0;
  text-align: center;
  font-weight: 600;
`;

const LOGIN_MUTATION = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      ok
      error
      token
    }
  }
`;

const Modal = ({ setModal }) => {
  const [login, { loading }] = useMutation(LOGIN_MUTATION);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    clearErrors,
  } = useForm({ mode: "onChange" });
  const onSubmitValid = async (params) => {
    const {
      data: {
        login: { ok, token, error },
      },
    } = await login({ variables: { ...params } });
    if (!ok) return setError("result", { message: error });
    if (token) logUserIn(token);
    setModal(false);
    // window.location.reload();
  };
  return (
    <ModalBg>
      <ModalWrapper>
        <ModalHeader>
          <ModalTitle>관리자 로그인</ModalTitle>
          <CloseBtn onClick={() => setModal(false)}>X</CloseBtn>
        </ModalHeader>
        <ModalContent>
          <Form onSubmit={handleSubmit(onSubmitValid)}>
            <Input
              {...register("username", { required: "아이디를 입력해주세요." })}
              placeholder="아이디"
              hasErr={Boolean(errors.username)}
              onKeyDown={() => clearErrors("result")}
            />
            {errors.username && (
              <FormError>{errors.username.message}</FormError>
            )}
            <Input
              {...register("password", { required: "비밀번호를 입력해주세요" })}
              type="password"
              placeholder="비밀번호"
              hasErr={Boolean(errors.password)}
              onKeyDown={() => clearErrors("result")}
            />
            {errors.password && (
              <FormError>{errors.password.message}</FormError>
            )}
            <SendBtn name="login" type="submit" disabled={!isValid}>
              {loading ? <FontAwesomeIcon icon={faSpinner} /> : "입력"}
            </SendBtn>
            {errors.result && <FormError>{errors.result.message}</FormError>}
          </Form>
        </ModalContent>
      </ModalWrapper>
    </ModalBg>
  );
};

export default Modal;
