import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { titleState } from "../state";
import { useEffect } from "react";

const Wrapper = styled.div`
  width: 100%;
`;
const Title = styled.div`
  text-align: center;
  color: #545354;
  h1 {
    font-size: 2.4rem;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
  p {
    font-size: 0.7rem;
    margin-bottom: 10px;
  }
  h4 {
    margin-top: 20px;
    font-size: 0.8rem;
    font-family: "Nanum Gothic", sans-serif;
  }
`;
const SubNav = styled.ul`
  margin-top: 40px;
  width: 100%;
  text-align: center;
  padding: 20px;
  border: 1px solid #ebebeb;
  border-left: 0;
  border-right: 0;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  li {
    font-size: 0.7rem;
    font-weight: 900;
    font-family: "Nanum Gothic";
    border-bottom: 1px solid #545454;
    margin: 0 15px;
    line-height: 1.2;
    a {
      color: rgb(84, 83, 84);
      cursor: pointer;
    }
  }
`;

const PageHeader = ({ title, subTitle, navList }) => {
  const setSlideTtile = useSetRecoilState(titleState);

  useEffect(() => setSlideTtile(title));

  return (
    <Wrapper>
      <Fade up cascade>
        <Title>
          <h1>{title}</h1>
          <p>Comfortable rest in your imagination,</p>
          <p>Pleasure meeting. It's an auxiliary facility prepared for you.</p>
          <h4>{subTitle}</h4>
        </Title>
        <SubNav>
          {navList.map((item, index) => {
            if (item.nav === "실시간예약")
              return (
                <li key={index}>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    {item.nav}
                  </a>
                </li>
              );
            return (
              <li key={index}>
                <Link to={item.url}>{item.nav}</Link>
              </li>
            );
          })}
        </SubNav>
      </Fade>
    </Wrapper>
  );
};

export default PageHeader;
