import styled, { css, keyframes } from "styled-components";
import PageHeader from "../../components/page/PageHeader";
import routes from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { useMutation, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";

const Wrapper = styled.section`
  padding: 15vh 0vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Nanum Gothic";
`;
const NoticeInputForm = styled.form`
  width: 100%;
  padding: 15vh 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TitleInputWrapper = styled.div`
  width: 100%;
  display: flex;
  label {
    width: 20%;
    margin-right: 2%;
    text-align: right;
  }
`;
const TitleInput = styled.input`
  width: 88%;
  border: 0.5px solid ${(props) => (props.hasErr ? "tomato" : null)};
`;
const ContentInputWrapper = styled.div`
  margin-top: 2vh;
  width: 100%;
  display: flex;
  label {
    width: 20%;
    margin-right: 2%;
    text-align: right;
  }
`;
const ContentInput = styled.textarea`
  width: 88%;
  height: 50vh;
  border: 0.5px solid ${(props) => (props.hasErr ? "tomato" : null)};
`;
const spinner = keyframes`
  from  { transform : rotate(0deg);}
  to { transform : rotate(360deg);}
`;
const SendBtn = styled.button`
  width: 80%;
  align-self: flex-end;
  border: 0;
  border-radius: 3px;
  margin: 2vh 0 1vh 0;
  padding: 0.5vh 0;
  font-size: 0.8rem;
  background-color: #787878;
  color: white;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  cursor: pointer;
  svg {
    animation: ${(props) =>
      props.name === "input"
        ? css`
            ${spinner} 1s ease infinite
          `
        : null};
  }
`;
const FormError = styled.span`
  color: tomato;
  font-size: 0.7rem;
  margin: 1vh 0 0vh 0;
  text-align: center;
  font-weight: 600;
`;

const CREATE_FAQ = gql`
  mutation createFaq($question: String!, $answer: String!) {
    createFaq(question: $question, answer: $answer) {
      ok
      error
      id
    }
  }
`;

const QuestionInput = () => {
  const navList = [
    { nav: "예약안내", url: routes.guide },
    {
      nav: "실시간예약",
      url: "https://booking.ddnayo.com/booking-calendar-status?accommodationId=11003&channelCode=0010",
    },
    { nav: "공지사항", url: routes.notices },
    { nav: "자주묻는질문", url: routes.questions },
  ];
  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
    setError,
    clearErrors,
  } = useForm({ mode: "onChange" });
  const [createFaq, { loading }] = useMutation(CREATE_FAQ);
  const history = useHistory();
  const onSubmitValid = async (params) => {
    const {
      data: {
        createFaq: { ok, error, id },
      },
    } = await createFaq({ variables: { ...params } });
    if (!ok) return setError("result", { message: error });
    history.push(`/reservation/question/${id}`);
  };
  return (
    <Wrapper>
      <PageHeader title="FAQ Input" subTitle="질문 입력" navList={navList} />
      <NoticeInputForm onSubmit={handleSubmit(onSubmitValid)}>
        <TitleInputWrapper>
          <label> 질 문 : </label>
          <TitleInput
            {...register("question", { required: "질문을 입력하세요" })}
            hasErr={Boolean(errors.question)}
            onKeyDown={() => clearErrors("result")}
          />
        </TitleInputWrapper>
        {errors.question && <FormError>{errors.question.message}</FormError>}
        <ContentInputWrapper>
          <label> 답 변 : </label>
          <ContentInput
            type="textarea"
            {...register("answer", { required: "답변을 입력하세요" })}
            hasErr={Boolean(errors.answer)}
            onKeyDown={() => clearErrors("result")}
          />
        </ContentInputWrapper>
        {errors.answer && <FormError>{errors.answer.message}</FormError>}
        <SendBtn name="input" type="submit" disabled={!isValid}>
          {loading ? <FontAwesomeIcon icon={faSpinner} /> : "입력"}
        </SendBtn>
        {errors.result && <FormError>{errors.result.message}</FormError>}
      </NoticeInputForm>
    </Wrapper>
  );
};

export default QuestionInput;
